import React from "react"
import { MutationCache, QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { toast, ToastContainer } from "react-toastify"

const queryClient = new QueryClient({
  mutationCache: new MutationCache({
    onError: (error: any, variables, context, mutation) => {
      const notify = ()=>toast.error(error.message, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        style: {
            fontSize: '14rem',
        }
      });
      if (mutation.meta && 'isShowToast' in mutation.meta) {
        if (mutation.meta?.isShowToast) {
          notify()
        }
      } else {
        notify()
      }
    },
  }),
  defaultOptions:{
    queries:{
      staleTime: 3000,
    },
    // mutations:{
    //   onError:err => {
    //     toast.error(err.message, {
    //       position: "bottom-center",
    //       autoClose: 5000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined,
    //       theme: "colored",
    //       style: {
    //         fontSize: '14rem',
    //       }
    //     });
    //   }
    // }
  }
})


const RootElement = ({ children }) => {
  return (<>
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    <ToastContainer />
  </>)
}

export default RootElement
