export function resizeRem({ win, doc, onResize }: { win: Window, doc: Document, onResize?: (rem: number) => void }) {
  function setFontSize() {
    // var baseFontSize = 100;
    // var baseWidth = 320;
    const clientWidth = doc.documentElement.clientWidth || window.innerWidth
    // var innerWidth = Math.max(Math.min(clientWidth, 480), 320);
    // let rem = 100;

    let rem = clientWidth / 360
    // if (innerWidth > 362 && innerWidth <= 375) {
    //   rem = Math.floor((innerWidth / baseWidth) * baseFontSize * 0.9);
    // }
    // if (innerWidth > 375) {
    //   rem = Math.floor((innerWidth / baseWidth) * baseFontSize * 0.84);
    // }
    // @ts-ignore
    win.__baseREM = rem
    // @ts-ignore
    // @ts-ignore
    doc.querySelector("html").style.fontSize = rem + "px"
    onResize?.(rem);
  }

  const evt = "onorientationchange" in win ? "orientationchange" : "resize"
  let timer = null
  win.addEventListener(
    evt,
    function() {
      clearTimeout(timer)
      timer = setTimeout(setFontSize, 300)
    },
    false
  )
  win.addEventListener(
    "pageshow",
    function(e) {
      if (e.persisted) {
        clearTimeout(timer)
        timer = setTimeout(setFontSize, 300)
      }
    },
    false
  )
  setFontSize()
}
