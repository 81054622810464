import "normalize.css"
import "./src/styles/global.css"
import React from "react"
import { resizeRem } from "./src/utils/resizeRem"
import RootElement from './src/components/root-element';
import "react-toastify/dist/ReactToastify.css"

export const onClientEntry = () => {
    resizeRem({ win: window, doc: document })
}

export const wrapRootElement = ({ element }) => {
    return <RootElement>{element}</RootElement>;
};
